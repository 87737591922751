<template>
  <div>
    <router-view />
    <SnapcallSupport
      v-if="showSnapcall"
      :snapcall-url="snapcallUrl"
      :show-snapcall="showSnapcall"
      @end-snapcall="endCall"
    />
  </div>
</template>

<script>
import { SnapcallSupport } from '@team-crm-automation/nmp-components-vue2'
export default {
  name: 'App',
  components: { SnapcallSupport },
  computed: {
    showSnapcall() {
      return this.$store.getters['snapcall/showSnapcall']
    },
    snapcallUrl() {
      return `/snapcall.html?bid=${this.$store.getters['snapcall/snapcallId']}`
    }
  },
  methods: {
    endCall() {
      this.$store.dispatch('snapcall/endSnapcall')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.menu-account {
  width: 240px;

  .el-dropdown-menu__item {
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: 1px solid #eef1f6;
    color: $--color-text-secondary;

    &:hover {
      color: $--color-text-secondary;
    }
  }

  i {
    vertical-align: text-bottom;
    font-size: 18px;
    width: 30px;
    text-align: center;
  }
}

.menu-account--detail-container {
  padding: 20px 10px 20px;
  text-align: center;
  font-size: 14px;
}

.menu-account--avatar-container {
  width: 48px;
  height: 48px;
  margin: 0 auto 10px;
}

.menu-account--avatar {
  width: 48px;
  height: 48px;
  box-shadow: none;
  border-radius: 50%;
}

.menu-account--email {
  color: #aaa;
}
</style>
