<template>
  <div
    class="tag"
    :style="cssVar"
  >
    <span class="tag__label">{{ label }}</span>
    <span
      class="tag__action"
      @click="clear()"
    >
      <i class="material-icons">clear</i>
    </span>
    <span class="tag__bg" />
  </div>
</template>

<script>
export default {
  name: 'FilterTag',
  props: {
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: () => '#CC0000'
    }
  },
  computed: {
    /**
     * Import js color var in CSS
     */
    cssVar() {
      return {
        '--tag-color': this.color
      }
    }
  },
  methods: {
    clear () {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss">
.tag {
  display: flex;
  border: 1px solid ;
  position: relative;
  align-items: center;
  border-radius: 2px;
  color: var(--tag-color);
  border-color: var(--tag-color);
  .tag__bg {
    position: absolute;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    background-color: var(--tag-color);
  }
  .tag__label {
    line-height: 20px;
    margin: 3px 4px 3px 10px;
  }
  .tag__action {
    z-index: 10;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
    align-items: center;
    margin-right: 6px;
    justify-content: center;
    .material-icons {
      font-size: 10px;
    }
    &:hover {
      background-color: var(--tag-color);
      .material-icons {
        color: white;
        opacity: 0.5;
      }
    }
  }
}
</style>
